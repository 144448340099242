.nav-wrapper {
  padding: 0 2vh;
}


.s_navbar{
  display: none;
  font-family: 'SairaCondensed';
}

.navbar {
  max-width: 100%;
  background-color: rgba(255, 255, 255, 0); /* Transparent white */
  font-family: 'SairaCondensed';
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav .nav-link img{
  max-height: 75px;
  padding: 2vh 0;
}

nav .nav-link {
  display: flex;
  font-size: 1.5rem;
  text-decoration: none;
  color: #ffffff;
}

.nav-link.active {
  font-weight: bold; /* Aktiver Link ist fett */
}

.nav-link:hover {
  background-color: var(--button-hover-color); /* Farbwechsel beim Hover */
  border-radius: 5px;
}

.nav-btn {
  display: none;
  opacity: 0;
  background-color: transparent;
}

.nav-btn:hover {
  background-color: transparent;
}
 
@media (max-width: 800px) {

  nav img{
    max-height: 75px;
    padding-top: 75px;
    margin-bottom: 75px;
  }
  
  nav .nav-link {
    display: flex;
    text-decoration: none;
    color: white;
    align-self: flex-start;
    margin-bottom: 1.5vh;
    margin-left: 4vh;
  }

  .nav-wrapper {
    position: fixed;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 18.76px;
    width: 475px;
    z-index: 1;
    max-width: 100%;
  }

  .navbtn-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
  }

  .navbar {
    display: none;
  }

  .nav-btn {
    display: flex;
    opacity: 1;
    font-size: 18px;
    padding-top: 3vh;
  }

  .s_navbar {
    background-color: rgb(0, 140, 240, 0.8);
    border-top-left-radius: 20px;
    position: fixed;
    top: 0;
    right: 0;
    width: 35%;
    height: 101vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(100vh);
  }

  .s_responsive_nav {
    transform: none;
    transition: 1s;
    z-index: 1;
  }
  .nav-wrapper-active {
    transform: none;
  }
}

  