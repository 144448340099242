.sp-section1 {
    display: flex;
    flex-direction: column;
}

.sp-section1 h1 {
    padding: 2vh;
} 

.sp-section1 h4 {
    padding: 2vh;
}
 
.sp-section1-h {
    display: flex;
    gap: 1vh;
} 

.sp-section2 {
    padding: 2vh;
} 

.sp-section2-text {
    display: flex;
    flex-direction: column;
}

.sp-section3-h {
    grid-row: 1;
    grid-column: 1 / 4;
    justify-self: center;
    border-bottom:  4px solid var(--primary-blue);
}

.sp-section3-h h2 {
    margin-bottom:0;
}

.sp-section3-b1, .sp-section3-b2, .sp-section3-b3 {
    background-color: #D9D9D9;
    border-radius: 20px;
    padding: 2vh;
}
.sp-section3-b1 {
    grid-row: 2;
    grid-column: 1;
}

.sp-section3-b2 {
    grid-row: 2;
    grid-column: 2;
}

.sp-section3-b3 {
    grid-row: 2;
    grid-column: 3;
}

.sp-section4 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    max-width: 100%;
    column-gap: 2vh;
    padding: 2vh;
  }

.sp-section4-img {
    grid-column: 1;
    grid-row: 1 / 3;
    display: flex;
    align-self: center;
    justify-self: flex-start;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1 / 1;
  }

  .sp-section5-img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: auto;
  }

  .sp-section5-text {
    max-width: 100%;
    background-color: rgb(0,0,0,0.6);
    padding: 50px;
  }