.impressumsection {
    margin-top: 100px;
    margin-bottom: 100px;
}

.impressum-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert die Inhalte vertikal */
    justify-content: flex-start; /* Setzt Abstand zwischen Bild und Text */
    padding: 1vh 5vw; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 1200px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.impressum-titel {
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert die Inhalte vertikal */
    padding: 20px; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 1200px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.impressum-text {
    background: var(--primary-blue);
    border-radius: 10px; /* Abgerundete Ecken */
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    padding: 2%; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 75%; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.impressum-text p {
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .impressum-text h1 {
        font-size: 1.5rem;
    }

    .impressum-text p {
        font-size: 1rem;
    }
}