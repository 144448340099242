.uu-section3 {
    display: flex;
    gap: 5vh;
    justify-content: space-between;
    padding: 5vh 2vh;
}

.uu-section3-sub {
    max-width: 60%;
} 

.uu-section3-h {
    display: flex;
    border-bottom: 0.5vh solid var(--primary-blue);
}

.uu-section3-img {
    display: flex;
    align-self: center;
    justify-self: flex-end;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 1 / 1;
} 

.uu-section5-h {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.uu-section5-sub {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 5vh;
    align-items: center;
}   

.uu-section5-sub-text {
    max-width: 60%;
}

.uu-section5-1h {
    display: flex;
    align-items: center;
}

.uu-section5-1-img, .uu-section5-2-img, .uu-section5-3-img {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    aspect-ratio: 4 / 3;

}

@media (max-width: 800px) {
    .uu-section5-sub {
        flex-direction: column;
    }
}