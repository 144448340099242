.quiz-section{
    margin-top: 100px;
    margin-bottom: 100px;
}

.quiz-content{
    display: flex;
    flex-direction: row;
    align-items: center; /* Zentriert die Inhalte vertikal */
    justify-content: flex-start; /* Setzt Abstand zwischen Bild und Text */
    padding: 1vh 5vw; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 1200px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.quiz-title {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert die Inhalte vertikal */
    padding: 1vh 5vw; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 1200px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.quiz-fragen {
    background: rgba(0, 168, 107, 0.25);
    border-radius: 10px; /* Abgerundete Ecken */
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding: 1vh 5vw; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 700px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}

.quiz-fragen li {
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.quiz-fragen li.selected {
    background-color: var(--button-hover-color);
}

.nr-frage {
    display: flex;
    flex-direction: column;
    align-items: center; /* Zentriert die Inhalte vertikal */
    padding: 1vh 5vw; /* Vertikaler Abstand basierend auf der Höhe und horizontaler Abstand basierend auf der Breite des Viewports */
    max-width: 1200px; /* Maximalbreite des Containers */
    margin: 0 auto; /* Zentriert den Container */
}