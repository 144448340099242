img {
  display: block;
  max-width: 100%;
}

.hp-section1 {
  display: flex;
  flex-direction: column;
  padding: 0 4vh;
  height: fit-content;
  max-width: 100%;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 75%);
}

.hp-section1-text {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  margin-bottom: 250px; /* Box height towards bottom */
}

.hp-section2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  column-gap: 2vh;
}

.hp-section2-img {
  grid-column: 1;
  grid-row: 1/3;
  width: 100%;
  max-width: 400px;
  height: fit-content;    /* Set to fit content */
  align-self: center;
  justify-self: center;
  aspect-ratio: 1 / 1;
}

.hp-section2-h {
  grid-column: 2;
  display:flex;
  align-items: center;
}

.hp-section2-text {
  grid-column: 2;
} 

.hp-section3 {
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  max-width: 100%;
  height: auto;
}

.hp-example-pictures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  align-items: center;
  padding: 5vh 2vh;
  max-width: 100%;
  column-gap: 2vh;
}

.hp-example-pictures img {
  width: 100%;
  max-height: 100px; 
  width: auto;
  justify-self: center;
}

.hp-section4-img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: auto;
}

.hp-section4-text {
  max-width: 75%;
  background-color: rgb(0,0,0,0.6);
  padding: 50px;
  margin: 25px;
}

.hp-section5 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  gap: 1vh;
  padding: 2vh;
}

.hp-section5-h {
  grid-column: 1/4;
  display:flex;
  align-items: center;
}

.hp-section5-foto {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
} 

.hp-section5-style {
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hp-section5-tauschen {
  grid-column: 3;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.hp-section5-button {
  grid-column: 2;
  grid-row: 3;
  justify-self: center;
  background-color: var(--primary-blue);
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: fit-content;
  text-decoration: none;
}

.hp-section6 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  padding: 2vh;
  row-gap: 2vh;
}

.hp-section6-h {
  grid-column: 1/3;
  display: flex;
  align-items: center;
  gap: 10px;
}
.hp-section6-1 {
  grid-column: 1 / 3;
  grid-row: 2;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  column-gap: 2vh;
}

.hp-section6-1h {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
}

.hp-section6-1text {
  grid-column: 1;
  grid-row: 2;
}

.hp-section6-1-img {
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  align-self: center;
  justify-self: flex-end;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1 / 1;
}

.hp-section6-2 {
  grid-column: 1 / 3;
  grid-row: 3;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  column-gap: 2vh;
}

.hp-section6-2h {
  grid-column: 2;
  grid-row: 1;
  display: flex;
  align-items: center;
}

.hp-section6-2text {
  grid-column: 2;
  grid-row: 2;
}

.hp-section6-2-img {
  grid-column: 1;
  grid-row: 1 / 3;
  display: flex;
  align-self: center;
  justify-self: flex-start;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1 / 1;
}

.hp-section6-3 {
  grid-column: 1 / 3;
  grid-row: 4;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  max-width: 100%;
  column-gap: 2vh;
}

.hp-section6-3h {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
}

.hp-section6-3text {
  grid-column: 1;
  grid-row: 2;
}

.hp-section6-3-img {
  grid-column: 2;
  grid-row: 1 / 3;
  display: flex;
  align-self: center;
  justify-self: flex-end;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1 / 1;
}

@media (max-width: 800px) {
  .hp-section5-button{
    margin-top: 2vh;
    font-size: 14px;
  }
}