.pitch-section h1 {
    font-size: 2rem;
}

.pitch-section h2 {
    color: white;
}

.pitch-section p {
 font-size: 1.5rem;
} 

.pitch-section {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: auto;
    gap: 50px;
}

.obox-1, .obox-2, .obox-3, .obox-4 {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;
}

.pitch-headline {
    grid-column: 1 / 3;
    grid-row: 1;
    display: flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
}

.obox-1 {
    grid-column: 1 / 2;
    grid-row: 2;
}

.obox-2 {
    grid-column: 2 / 3;
    grid-row: 2;
}

.obox-3 {
    grid-column: 1 / 2;
    grid-row: 3;
}

.obox-4 {
    grid-column: 2 / 3;
    grid-row: 3;
}

.prozess-headline {
    padding-bottom: 2rem;
    padding-left: 2rem;
}
.prozess-section {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: repeat(4, auto);
    gap: 20px;
}

.prozess-section p {
    font-size: 2rem;
}

.prozess-section p {
    font-size: 1.5rem;
}

.sidebar {
    grid-column: 1;
    grid-row: 1 / 5;
    justify-self: center; 
    background-color: white;
    border-radius: 40px;
    width: 33px;
}

.circle-1, .circle-2, .circle-3, .circle-4 {
    justify-self: center; 
    align-self: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center; 
} 

.circle-1 {
    grid-column: 1;
    grid-row: 1;
}

.circle-2 {
    grid-column: 1;
    grid-row: 2;
}

.circle-3 {
    grid-column: 1;
    grid-row: 3;
}

.circle-4 {
    grid-column: 1;
    grid-row: 4;
}

.step1, .step2, .step3, .step4 {
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;
}

.step1 {
    grid-column: 2;
    grid-row: 1;
}

.step2 {
    grid-column: 2;
    grid-row: 2;
}

.step3 {
    grid-column: 2;
    grid-row: 3;
}

.step4 {
    grid-column: 2;
    grid-row: 4;
}

.o-icon {
    color: #FF5C00;
    height: 50px;
    width: 50px;
    align-self: center;
}

@media (max-width: 768px) {
    .pitch-section {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .pitch-section h1 {
        font-size: 1.5rem;
    }
    
    .pitch-section h2 {
        font-size: 2rem;
    }
    
    .pitch-section p {
     font-size: 1.125rem;
    } 

    .prozess-headline {
        padding-left: 1rem;
    }
    .prozess-headline h1{
        font-size: 1.5rem;
    }
    .sidebar {
        border-radius: 40px;
        width: 16.5px;
    }
    
    .circle-1, .circle-2, .circle-3, .circle-4 {
        width: 50px;
        height: 50px;
    } 
    .prozess-section h1 {
        font-size: 1.5rem;
    }

    .prozess-section p {
        font-size: 1.125rem;
    }

    .o-icon {
        height: 25px;
        width:25px;
    }
}
