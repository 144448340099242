  .upload-page {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .image-container {
    display: flex;
    justify-content: space-around; /* Space images evenly */
    align-items: center;
    margin-bottom: 2vh;
}

.responsive-image {
    max-width: 35%; /* Adjust size relative to the container */
    height: auto; /* Maintain aspect ratio */
    max-height: 300px;
    border: 1px solid #ddd; /* Optional: Add a border */
    border-radius: 5px; /* Optional: Add rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow */
}

  input[type="text"] {
    outline: none;
    border-radius: 5px;
    font-size: medium;
    line-height: normal;
    padding-left: 10px;
    border: 2px solid rgba(0, 140, 240, 0.25);
    background: rgba(0, 140, 240, 0.25);
    transition: 0.1s ease;
    z-index: 1111;
    max-width: 150px;
    width: 50%;
  }

  input[type="text"]:focus {
    border: 2px solid rgb(0, 140, 240)
  }

  .rounded-box {
    display: flex;
    width: 75%;
    justify-content: center; /* Centers the child elements horizontally */
    margin: 0 auto;
  }

  .rounded-box-horizontal {
    display: flex;
    flex-direction: column;
    max-width: 640px;
    width: 90%;
    justify-content: center; /* Centers the child elements horizontally */
    margin: 75px auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
  }

  .instruction-box {
    width: 33.333%;
    padding: 0 2vh;
    box-sizing: border-box;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: var(--primary-blue);
  }

  .input-box {
    box-sizing: border-box;
    padding: 0 2vh;
    display: flex;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
  }

  .instruction-box-horizontal {
    padding: 0 2vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: var(--primary-blue);
  }

  .input-box-horizontal {
    padding: 0 2vh;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    gap: 2vh;
  }

  .image-placeholder {
    width: 50%; /* Adjust to fit your container */
    
    height: 200px; /* Placeholder height */
    background-color: lightgray; /* Light gray background for the placeholder */
    
    display: flex;
    align-items: center; /* Center the content vertically */
    justify-content: center; /* Center the content horizontally */
    align-self: center;
    border-radius: 10px; /* Optional rounded corners */
    overflow: hidden;
    margin-bottom: 2vh; /* Ensures the image doesn't overflow */
  }

  .hide {
    display: none;
  }

  .label-button {
    background-color: var(--primary-blue);
    color: #ffffff;
    font-size: 18px;
    padding: 2px 0px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50%;
    max-height: fit-content;
    text-align: center;
  }

  .label-button:hover {
    background-color: var(--button-hover-color);
  }
  
  .center {
    align-self: center;
  }

  .image-placeholder img {
    width: 100%; /* Scale image to fit placeholder width */
    height: 100%; /* Scale image to fit placeholder height */
    object-fit: contain; /* Keep image aspect ratio */
  }
  
  .image-placeholder p {
    margin: 0;
    font-size: 14px;
    color: #666;
    text-align: center;
  }
  
.name-input {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  gap: 2vh;
  margin-bottom: 2vh;
}


.name-fields {
  width: 50%;
  display: flex;
  justify-content: space-between;
} 

select {
  max-width: fit-content;
  margin-bottom: 2vh;
  font-size: 18px;

}

.preview-box {
  display: flex;
  flex-direction: row;
}

.iframe-placeholder {
  width: 100%; /* Adjust to fit your container */
  height: 250px; 
  background-color: lightgray; /* Light grey background */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Optional rounded corners */
}

.loading-spinner {
  border: 4px solid #ccc; /* Light grey border */
  border-top: 4px solid #3498db; /* Blue spinner */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pdf-preview iframe {
  width: 100%;
  height: 300px;
  border: none;
}

.pdf-preview p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.pdf-preview {
  width: 100%; /* Adjust to fit your container */
  max-width: 200px; /* Optional: Set a max width */
  align-self: center;
}

.flex-row {
  flex-direction: row;
  justify-content: space-evenly;
}

.input-box-horizontal button {
  align-self: flex-end; /* Optional: Align button to the right */
  margin-top: auto; /* Ensure it's pushed to the bottom */
}

.checkbox-input {
  margin-top: 5px;
} 

@media (max-width: 800px) {
  .flex-row {
    flex-direction: column;
  }

  .label-button {
    font-size: 16px;
  }

  .name-input label {
    font-size: 16px;
  }

  .name-fields {
    width: 75%;
  } 

  .checkbox-input {
    margin-top: 4px;
  } 
}