/* Global style definitions */

:root {
  /* Color scheme for elements that are not texts */
  --background-color: #EAEAEA;
  --button-hover-color: #58b9ff;
  --primary-blue: rgb(0, 140, 240);
}

/* Font face definition */
@font-face {
  font-family: 'SairaCondensed';
  src: url('./assets/fonts/Saira_Condensed/SairaCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Body and typography */
body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

a, p, h1, h2, h3, h4, h5, h6, label {
  font-family: 'SairaCondensed';
}

h1 {
  font-size: 40px;
  line-height: 1;
  font-weight: bold;
  color: #ffffff;
}

.h1-black {
  color: #1B1B1B;
}

.h1-blue {
  color: var(--primary-blue);
}

h2 {
  font-size: 32px;
  line-height: auto;
  color:#1B1B1B;
}

h3 {
  font-size: 27px;
  line-height: auto;
  font-weight: bold;
  color: #1B1B1B;
}

h4 {
  font-size: 24px;
  line-height: auto;
  color: #757575;
  font-weight: lighter;
}

.h4-white {
  color: #EAEAEA;
}

.h4-blue {
  color: var(--primary-blue);
} 

p {
  font-size: 18px;
  line-height: auto;
  color: #1B1B1B;
}

label {
  font-size: 18px; /* Adjust font size */
  color: black; /* Label text color */
}

.p-white {
  color: white;
}

.p {
  font-size: 18px;
  line-height: auto;
  color: #1B1B1B;
  font-family: 'SairaCondensed';
}

button {
  background-color: var(--primary-blue);
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  max-width: fit-content;
  max-height: fit-content;
}

button:disabled {
  background-color: gray; /* Disabled button color */
  color: #ccc; /* Lighter text for disabled button */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
  opacity: 0.7; /* Make it slightly transparent */
}

button:hover {
  background-color: var(--button-hover-color);
}

.bluebar {
  background-color: #008CF0;
  height: 50px;
  width: 10px;
  margin-right: 25px;
}

.icon {
  max-width: 100%;
  font-size: 37px;
  color: #1B1B1B;
}

.container {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  /*border: 2px solid red;*/
}

@media (max-width: 800px) {
  .container {
    max-width: 475px;
  }

  h1 {
    font-size: 28px; 
    line-height: 1.2;
  }

  .h1-blue {
    color: var(--primary-blue);
  }

  h2 {
    font-size: 24px; 
    line-height: 1.2;
  }

  h3 {
    font-size: 20px; 
    line-height: 1.2;
    font-weight: bold;
  }

  h4 {
    font-size: 18px; 
    line-height: 1.2;
    color: #757575;
  }

  .h4-white {
    color: #EAEAEA;
    margin: auto;
  }

  p {
    font-size: 16px; 
    line-height: 1.5; 
  }
}