.footer {
  font-family: 'SairaCondensed';
  display: flex;
  justify-content: space-evenly;
  background-color: #1681f5;
  height: 15vh;
  min-height: 90px;
  padding-bottom: 2vh;
}

.footer-img {
  aspect-ratio: 4 / 5;
}

.nav-link {
  text-decoration: none;
  color: white;
}

.footer-links-left, .footer-links-right {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: 2vh;
}

.nav-link.active {
  font-weight: bold;
}

@media (max-width: 800px) {
.nav-link {
  font-size: 14px;
}

} 