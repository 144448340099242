.persönlichkeitsrecht-text {
    margin: 0 5vh;
}

.persönlichkeitsrecht-text h2 {
    margin-bottom: 0;
}

.persönlichkeitsrecht-text h3 {
    margin-bottom: 0;
}

.persönlichkeitsrecht-container {
    margin-bottom: 45vh;
}